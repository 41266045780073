import { authConstants, status } from "../_constants";

let initialState = {};

export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstants.REGISTER_USER_REQUEST:
            return {
                ...state,
                register_status: status.IN_PROGRESS,
                registration_data: action.data,
            };
        case authConstants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                register_status: status.SUCCESS,
                registration_data: action.data,
            };
        case authConstants.REGISTER_USER_FAILURE:
            return {
                ...state,
                register_status: status.FAILURE,
                registration_data: action.data,
            };
        case authConstants.USER_LOGOUT:
            return {
                ...state,
                user: null,
            };
        case authConstants.USER_LOGIN_REQUEST:
            return {
                ...state,
                user_login_status: status.IN_PROGRESS,
                user: action.data,
            };
        case authConstants.USER_LOGIN_SUCCESS:
            return {
                ...state,
                user_login_status: status.SUCCESS,
                user: action.data,
            };
        case authConstants.USER_LOGIN_FAILURE:
            return {
                ...state,
                user_login_status: status.FAILURE,
                user: action.data,
            };

        case authConstants.RESEND_OTP_REQUEST:
            return {
                ...state,
                resend_otp_status: status.IN_PROGRESS,
                resend_otp_res: action.data,
            };
        case authConstants.RESEND_OTP_SUCCESS:
            return {
                ...state,
                resend_otp_status: status.SUCCESS,
                resend_otp_res: action.data,
            };
        case authConstants.RESEND_OTP_FAILURE:
            return {
                ...state,
                resend_otp_status: status.FAILURE,
                resend_otp_res: action.data,
            };
        case authConstants.USER_DATA_REQUEST:
            return {
                ...state,
                user_data_status: status.IN_PROGRESS,
                user_data_res: action.data,
            };
        case authConstants.USER_DATA_SUCCESS:
            return {
                ...state,
                user_data_status: status.SUCCESS,
                user_data_res: action.data,
            };
        case authConstants.USER_DATA_FAILURE:
            return {
                ...state,
                user_data_status: status.FAILURE,
                user_data_res: action.data,
            };
        case authConstants.UPDATE_USER_DATA_REQUEST:
            return {
                ...state,
                update_user_data_status: status.IN_PROGRESS,
                update_user_data_res: action.data,
            };
        case authConstants.UPDATE_USER_DATA_SUCCESS:
            return {
                ...state,
                update_user_data_status: status.SUCCESS,
                update_user_data_res: action.data,
            };
        case authConstants.UPDATE_USER_DATA_FAILURE:
            return {
                ...state,
                update_user_data_status: status.FAILURE,
                update_user_data_res: action.data,
            };

        case authConstants.ADDRESS_REQUEST:
            return {
                ...state,
                address_status: status.IN_PROGRESS,
                address_res: action.data,
            };
        case authConstants.ADDRESS_SUCCESS:
            return {
                ...state,
                address_status: status.SUCCESS,
                address_res: action.data,
            };
        case authConstants.ADDRESS_FAILURE:
            return {
                ...state,
                address_status: status.FAILURE,
                address_res: action.data,
            };
        case authConstants.USER_ADDRESS_REQUEST:
            return {
                ...state,
                user_address_status: status.IN_PROGRESS,
                user_address_res: action.data,
            };
        case authConstants.USER_ADDRESS_SUCCESS:
            return {
                ...state,
                user_address_status: status.SUCCESS,
                user_address_res: action.data,
            };
        case authConstants.USER_ADDRESS_FAILURE:
            return {
                ...state,
                user_address_status: status.FAILURE,
                user_address_res: action.data,
            };
        case authConstants.UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                update_address_status: status.IN_PROGRESS,
                update_address_res: action.data,
            };
        case authConstants.UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                update_address_status: status.SUCCESS,
                update_address_res: action.data,
            };
        case authConstants.UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                update_address_status: status.FAILURE,
                update_address_res: action.data,
            };

        case authConstants.SUBSCRIBE_REQUEST:
            return {
                ...state,
                subscribe_status: status.IN_PROGRESS,
                subscribe_res: action.data,
            };
        case authConstants.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                subscribe_status: status.SUCCESS,
                subscribe_res: action.data,
            };
        case authConstants.SUBSCRIBE_FAILURE:
            return {
                ...state,
                subscribe_status: status.FAILURE,
                subscribe_res: action.data,
            };

        case authConstants.DELETE_ADDRESS_REQUEST:
            return {
                ...state,
                delete_address_status: status.IN_PROGRESS,
                delete_address_res: action.data,
            };
        case authConstants.DELETE_ADDRESS_SUCCESS:
            return {
                ...state,
                delete_address_status: status.SUCCESS,
                delete_address_res: action.data,
            };
        case authConstants.DELETE_ADDRESS_FAILURE:
            return {
                ...state,
                delete_address_status: status.FAILURE,
                delete_address_res: action.data,
            };

        default:
            return state;
    }
}
